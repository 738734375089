export default class MatchHeight {
  constructor(targetClass, observeClass) {
    this.targetClass = targetClass;
    this.targetEls = Array.from(document.querySelectorAll(targetClass));
    this.observeEl = document.querySelector(observeClass);
    this.init();
  }

  _row(elements) {
    const tolerance = 1;
    let lastTop = null;
    let rows = [];

    elements.forEach(el => {
      const top = el.getBoundingClientRect().top;
      const lastRow = rows.length > 0 ? rows[rows.length - 1] : null;

      if (!lastRow || Math.floor(Math.abs(lastTop - top)) > tolerance) {
        rows.push([el]);
      } else {
        lastRow.push(el);
      }

      lastTop = top;
    });

    return rows;
  }

  _reset(els) {
    els.forEach(el => {
      el.style.height = 'auto';
    });
  }

  _match(els) {
    this._reset(els);
    const max_h = Math.max(...els.map(el => el.clientHeight));
    els.forEach(el => {
      el.style.height = max_h + 'px';
    });
  }

  _bind(els) {
    const rows = this._row(els);
    rows.forEach(row => {
      this._match(row);
    });
  }

  _setObserver(targetEls, observeEl) {
    this.observer = new ResizeObserver(() => {
      this._bind(targetEls);
    });
    this.observer.observe(observeEl);
  }

  init() {
    this._setObserver(this.targetEls, this.observeEl);
  }

  update() {
    this.targetEls = Array.from(document.querySelectorAll(this.targetClass));
    this.observer.disconnect();
    this._setObserver(this.targetEls, this.observeEl);
  }
}
