import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Navigation, Pagination } from 'swiper/modules';

new Swiper('.home-visual__slider .swiper', {
  modules: [Navigation, Autoplay, EffectFade],
  slidesPerView: 1,
  effect: 'slide',
  speed: 1000,
  loop: true,
  loopAdditionalSlides: 1,
  spaceBetween: 20,
  centeredSlides: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  // autoplay: {
  //   delay: 3000,
  //   disableOnInteraction: false, // 矢印をクリックしても自動再生を止めない
  // },
  breakpoints: {
    769: {
      slidesPerView: 1.5,
      spaceBetween: 40,
    }
  }
});

new Swiper('.homeSec01-slider .swiper', {
  modules: [Pagination, Autoplay, EffectFade],
  slidesPerView: 1,
  effect: 'fade',
  speed: 1000,
  loop: true,
  loopAdditionalSlides: 1,
  slidesPerView: 1,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 3000
  },
});

new Swiper('.homeSec06__slider .swiperHomeSec06', {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  loop: true,
  spaceBetween: 20,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    769: {
      slidesPerView: 1.4,
      spaceBetween: 40,
    }
  }
});

new Swiper('.homeSec11__slider .swiperHomeSec11', {
  modules: [Navigation],
  slidesPerView: 1.1,
  loop: true,
  spaceBetween: 40,
  centeredSlides: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    769: {
      slidesPerView: 1.2,
      spaceBetween: 50,
    }
  }
});
